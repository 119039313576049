@use "../config/" as *;
@forward "../custom/social-icons";
@forward "../custom/progress-bar";
.waf-player {
  &.waf-component {
    @extend %p-zero;
    @extend %m-t-zero;
    @extend %m-x-half-neg;
  }
  .waf-head {
    @extend %d-none;
  }
  .tab {
    &-name {
      @extend %text-center;
      .btn-text {
        @extend %c-blue-1-10;
      }
      &.active {
        border-bottom-color: var(--c-orange);
        .btn-text {
          font-family: $font-pb;
          @extend %c-blue-10;
        }
      }
    }
    &-item-wrapper {
      @extend %p-x-full;
      .text,.content-wrap {
        @include font(14,23,$font-pr);
        @extend %c-blue-2-8;
      }
      .head {
        @include flex-config(flex, null, space-between, center);
        @extend %m-b-two-space;
      }
      .title {
        font-size: 1.8rem;
        text-transform: uppercase;
        @extend %c-white-10;
      }
      .social-wrap,
      .select-box-wrap,
      .dropdown-close {
        @extend %d-none;
      }
    }
    &-title {
      font: 1.8rem/1 $font-pb;
      @extend %m-b-one-n-half;
      @extend %c-blue-10;
    }
    &-content {
      padding: var(--two-space) var(--full-space);
      border-radius: 1.2rem;
      margin-top: 7.5rem;
      @extend %c-blue-3-bg-10;
    }
    &-item-about {
      margin-top: 5rem;
    }
  }
  .content-wrap {
    margin-bottom: 5rem;
  }
  .waf-select-box {
    @extend %relative;
  }
  .select-box-wrap {
    width: 100%;
    border-radius: .2rem;
    z-index: var(--zindex-select-year);
    @include position(100%);
    @extend %c-grey-bg-10;
    .list-item {
      @extend %p-half;
      @extend %c-white-10;
      @extend %truncate-line;
    }
  }
  .player-result-title {
    height: 4rem;
    border-bottom: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
    font-size: 1.4rem;
    gap: 1.2rem;
    @include flex-config(flex, null, null, center);
    @extend %c-white-10;
    @extend %truncate-line;
    &::after {
      content: "\e806";
      font: 2.4rem/1 $font-icon;
      transition: rotate .3s ease-in;
    }
    &[aria-expanded="true"] {
      &::after {
        rotate: 180deg;
      }
      ~ .select-box-wrap {
        @extend %d-block;
      }
    }
  }
}
@media (min-width: $desktop-min-width) {
  .waf-player {
    .card-information {
      position: relative;
      &::after {
        content: "";
        width: var(--window-inner-width);
        height: .1rem;
        z-index: var(--zindex-pattern);
        background-color: hsl(var(--hsl-c-black)/0.1);
        @include position(7rem, null, null, 0);
      }
    }
    .tab {
      &-container {
        margin-block: 6.5rem;
      }
      &s {
        margin-block: 0;
      }
      &-name {
        height: 7rem;
        padding: 0;
        border-bottom: 0;
        text-align: center;
        @include flex-config(flex, null, null, center);
        button {
          width: 100%;
        }
      }
      &-section {
        max-width: var(--container-max-width);
        margin-inline: auto;
      }
      &-item-wrapper {
        .text,.content-wrap {
          font-size: 1.8rem;
          line-height: 2.8rem;
        }
        .title {
          font-size: 2.4rem;
        }
        .head {
          gap: 5rem;
          justify-content: unset;
        }
      }
      &-title {
        font-size: 2.4rem;
      }
      &-content {
        padding: var(--one-n-half-space);
        border-radius: 2.4rem;
        margin-top: 4rem;
      }
    }
    .player-result-title {
      gap: 2.5rem;
    }
    .waf-select-box {
      &::before {
        content: "";
        width: .1rem;
        height: calc(100% - 1rem);
        background-color: hsl(var(--hsl-c-pure-white) / 0.2);
        @include position-combo(y-center, null, -2.5rem);
      }
    }
  }
}