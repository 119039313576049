@use "../config/" as *;
.waf-fixtures {
    &.waf-component {
        padding: 0;
    }
    .waf-accordion-title {
        .content-item {
            padding: var(--three-fourth-space) 4.5rem var(--three-fourth-space) 7.2rem;
        }
        .rank {
            font-family: $font-pb;
            @include position-combo("y-center", null, var(--three-fourth-space));
        }
        .image {
            width: 4.7rem;
            @extend %d-block;
            @extend %ratio-1-1;
        }
        .card {
            &-info {
                margin-bottom: .6rem;
                flex-direction: row;
                .text {
                    @extend %font-14-pb;
                    @extend %c-blue-2-10;
                }
            }
            &-meta {
                margin-bottom: 0;
                flex-wrap: wrap;
                gap: var(--half-space);
                justify-content: flex-start;
                .image {
                    width: 1.5rem;
                    @extend %ratio-1-1;
                }
                .meta:not(:last-child)::after {
                    content: "|";
                }
                .meta:not(:nth-last-child(2))::before {
                    content: unset;
                }
                .meta:last-child::after {
                    content: unset;
                }
            }
            &-action {
                width: 0;
                height: 0;
                position: static;
                .btn-link {
                    width: unset;
                    transform: unset;
                    @include position-combo("inset");
                    &::after {
                        font-weight: bold;
                        line-height: 1;
                        font-size: 2.4rem;
                        @include position-combo("y-center", 1rem);
                        @extend %circle-radius;
                        @extend %c-white-bg-10;
                    }
                }
            }
        }
        .meta {
            gap: var(--half-space);
            @extend %flex-n-c;
            @extend %font-14-pr;
            @extend %c-blue-2-10;
        }
    }
    .waf-accordion-panel {
        &:not(:last-child) {
            @extend %m-b-half;
        }
        .content-item {
            @extend %m-b-zero;
        }
        .card-content {
            .card-label {
                word-break: break-word;
            }
            .card-b {
                justify-content: flex-end;
            }
        }
        .card-meta {
            // Hiding match status(ie. live) as design not given
            .status {
                @extend %d-none;
            }
        }
    }
    .waf-accordion-title {
        &.active {
            .content-item {
                background-color: hsl(var(--hsl-c-light-grey-1)/0.6);
            }
        }
    }
}
.tab-item-result .waf-fixtures .waf-accordion-title .meta:not(:first-child)::before {
    content: unset;
}
@media (min-width:$desktop-min-width) {
    .waf-fixtures {
        .waf-accordion-title {
            .card {
                &-info {
                    width: unset;
                    flex-grow: 1;
                    .text {
                        font: 1.6rem $font-pr;
                    }
                    .rank {
                        font-size: 2.2rem;
                    }
                }
                &-action {
                    .btn-link {
                        .text::after {
                            right: var(--full-space);
                        }
                    }
                }
            }
            .meta {
                font-size: 1.6rem;
            }
            .content-wrapper {
                height: 100%;
            }
        }
        .waf-accordion-panel {
            transition: box-shadow .4s ease-in;
            &:hover {
                box-shadow: 0 .4rem 1.6rem 0 rgba(#4e4e4e, .1);
            }
            &:not(:last-child) {
                margin-bottom: var(--full-space);
            }
            .content-item {
                padding: var(--three-fourth-space) var(--full-space);
            }
            .card-meta {
                width: auto;
                margin-bottom: 0;
            }
            .card-content {
                width: unset;
                flex-grow: 1;
            }
            .card-info {
                margin-bottom: 0;
            }
            .card-action {
                width: auto;
                .btn-link {
                    position: static;
                    .text {
                        gap: var(--one-n-half-space);
                        transform: unset;
                        @include flex-config(flex, null, null, center);
                        &::after {
                            position: static;
                            transform: unset;
                        }
                    }
                }
            }
            .card-info,
            .card-item,
            .card-content {
                height: 100%;
            }
            .card-status,
            .card-number {
                align-self: flex-start;
            }
            .card-number {
                margin-bottom: 0;
            }
            .card-status {
                height: 3.6rem;
                .status {
                    font-size: 1.2rem;
                    white-space: nowrap;
                }
                .vs {
                    height: 100%;
                }
            }
            .won {
                .card-number {
                    font-family: $font-pr;
                }
            }
        }
        .filter-label-group {
            margin: 0;
        }
    }
    .tab-item-result .waf-fixtures {
        .waf-accordion-title {
            .content-wrapper {
                gap: var(--full-space);
            }
            .card-info {
                margin-bottom: 0;
                justify-content: flex-start;
            }
        }
        .waf-accordion-panel .card-info {
            justify-content: flex-end;
        }
    }
}