@use '../config/' as *;
.social-wrap {
  .social {
    &-item {
      @extend %flex;
      &-icon {
        display: block;
        span {
          @extend %h-100;
          @extend %flex-c-c;
          &::before {
            display: inline-block;
            font: 1.4rem/1 $font-icon;
            @extend %c-pure-white-10;
          }
        }
      }
      @each $icon-name, $icon-content in $social {
        &-#{$icon-name}::before {
          content: $icon-content;
        }
      }
    }
  }
}