@use "../config/" as *;
@forward "../custom/social-icons";
@forward "../custom/progress-bar";
// .waf-player-profile {
.player-result-wrapper {
  margin-block: 3.2rem 5rem;
  padding-bottom: calc(6*var(--full-space));
  @extend %p-t-two-space;
  @extend %p-x-full;
  @extend %relative;
  @extend %c-blue-bg-10;
  @include border-radius(1.2rem);
  &::after {
    content: 'These statistics pertain exclusively to UWW (United World Wrestling) competitions.';
    font-style: italic;
    @extend %font-12-pr;
    @extend %c-white-6;
    @include position(null,var(--full-space),var(--two-space),var(--full-space));
  }
}
.player-result-item {
  gap: var(--half-space);
  @include flex-config(flex, column);
  .item {
    padding-block: 1.2rem;
    gap: 1.2rem;
    @include flex-config(flex, null, null, center);
    &:not(:last-child) {
      border-bottom: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
    }
    &-wrapper {
      padding: 2rem var(--full-space);
      border: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
      border-radius: .8rem;
      gap: 1rem;
      @include flex-config(flex, column);
      @extend %c-pure-white-bg-1;
      @extend %c-grey-10;
      // &:nth-child(3) {
      //   .item-label {
      //     @extend %text-left;
      //   }
      // }
    }
    &-label {
      font: 1.4rem/1 $font-pb;
      text-transform: capitalize;
      @extend %text-center;
    }
    &-list {
      .item-label {
        font: 1.2rem/1 $font-pr;
      }
    }
  }
  .progress {
    &-view {
      gap: .2rem;
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      .number {
        @extend %c-white-10;
      }
    }
    &-item {
      .player-score {
        @extend %m-b-half;
        @extend %text-right;
      }
      &:last-child {
        .player-score {
          @extend %p-r-zero;
          @extend %text-left;
        }
      }
    }
  }
  .label {
    font-size: 1.4rem;
    text-transform: capitalize;
    @extend %d-block;
  }
  .number,
  .item-number {
    font: 2rem/1 $font-pb;
    @extend %c-orange-10;
  }
  .progress-item {
    &.win {
      .number {
        @extend %c-orange-10;
      }
      .player-bar-fill {
        @extend %c-orange-bg-10;
      }
    }
  }
}
@media (min-width: $desktop-min-width) {
  // .waf-player-profile {
  .player-result-wrapper {
    padding-inline: var(--one-n-half-space);
    padding-bottom: calc(5*var(--full-space));
    &::after {
      left: var(--one-n-half-space);
      right: var(--one-n-half-space);
    }
  }
  .player-result-item {
    display: grid;
    gap: var(--full-space);
    grid-template-areas: 'a a a'
      'b c c'
      'e e d';
    grid-template-columns: 40.5% auto 26.7%;
    .item {
      padding: 0 var(--half-space);
      position: relative;
      flex-direction: column;
      &:not(:last-child) {
        border-bottom: 0;
        &::after {
          content: "";
          width: .1rem;
          height: 100%;
          background-color: hsl(var(--hsl-c-pure-white) / 0.2);
          @include position-combo("tr");
        }
      }
      &-wrapper {
        &:first-child {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
        &:nth-child(5) {
          grid-area: d;
        }
        &:nth-child(4) {
          grid-area: e;
        }
      }
      &-label {
        font: 1.6rem/1 $font-pb;
      }
      &-number {
        font-size: 3.6rem;
      }
      &-list {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(0, 1fr);
      }
    }
    .number {
      font-size: 2.4rem;
    }
    .progress {
      &-item {
        display: flex;
        align-items: center;
        &.lost {
          flex-direction: row-reverse;
        }
        &:first-child .player-score {
          text-align: left;
        }
        &:last-child .player-score {
          text-align: right;
        }
      }
    }
  }
  .player-bar {
    flex-grow: 1;
  }
  .waf-player .item-wrapper {
    &:nth-child(2) {
      .item-list {
        position: relative;
        @include flex-config(flex, null, space-between);
      }
      .item {
        max-width: 10rem;
        padding-inline: 0;
        position: unset;
        align-items: flex-start;
        &:first-child::after {
          left: 33.33%;
        }
        &:nth-child(2)::after {
          left: 66.67%;
        }
        &-label {
          font-size: 1.6rem;
          text-align: left;
        }
      }
    }
    &:nth-child(5) {
      .item-label {
        font-size: 1.6rem;
      }
    }
  }
}
// }
@media (min-width: $large-desktop-min-width) {
  // .waf-player-profile {
  .player-result-item {
    .item-number {
      font-size: 4.8rem;
    }
  }
}
// }